import { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import styles from './App.module.css';
import NavbarB from './ui/NavbarBottom.js';

import Navbar from './ui/Navbar.js';
import Navbar0 from './ui/Navbar0.js';

import Home from './components/Home';
import Footer from './components/Footer.js';
import Download from './components/Download.js';
// const About = lazy(() => import('./components/About.js'));

function App() {

  const [languageEn, setLanguageEn] = useState(false)

  function handleLangChange() {
    setLanguageEn(!languageEn)
  }

  return (
    <div className={styles.app}>

      <Router>
        <header className={styles.header}>
          <Navbar0 />
          <Navbar languageEn={languageEn} handleLangChange={handleLangChange} />
          <NavbarB languageEn={languageEn} handleLangChange={handleLangChange} />

        </header>

        <div>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home english={languageEn} />} />
              {/* <Route path="/about" element={<About />}  /> */}
              <Route path="/download" element={<Download />} />

            </Routes>
          </Suspense>
        </div>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
