import React from "react"
import styles from "./Footer.module.css"
import Download from "./Download"


export default function Footer() {

  const bulgarian = {
    phone: "Телeфон: 08 777 1444 9",
    address: "Ул. Изглед, Бистрица, София, 1444, България",
  }

  return (
    <div className={styles.footerWrap}>
      {/* Links */}
      {/* Contacts */}
      <a href={"tel:00359877714449"}>{bulgarian.phone}</a>
      <a href="mailto:home@vkushty.com?subject=Посещение%20на%20място&body=Здравейте,%20искам%20да%20посетя%20обекта%20на:%20">
        Email: home@vkushty.com</a>

      <p>{bulgarian.address}</p>
      {/* <Download text={'Свали презентация'}/> */}
      
      <a href="https://maps.app.goo.gl/VCjbGqQwMN5BRztp7"
        target="_blank"
        rel="noopener noreferrer"
        className={styles.mapLink}>
          Виж Локацията на Google Maps</a>
      <p>Вкъщи ЕООД</p>
      {/* About */}
    </div>
  )
}