import React, { useRef, useState, useEffect } from "react"
import styles from "./Navbar.module.css"
import { NavHashLink } from 'react-router-hash-link';
import logo from "../assets/graphics/VkushtY-2.png"


export default function Navbar({ languageEn, handleLangChange }) {
  const [isSticky, setIsSticky] = useState(false);
  const headerRef = useRef(null);


  const bulgarian = {
    button: "Контакти",
    slogan: "Ела Вкъщи, Ела на Върха",
    number: "M 08777 1444 9",
  }
  const english = {
    button: "Contacts",
    slogan: "Welcome Vkushty",
    number: "M +359 8777 1444 9",
  }

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current && window.scrollY > (headerRef.current.offsetTop)) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={isSticky ? styles.navWrap : styles.navWrapNormal}
      ref={headerRef}>
      <div className={isSticky ? styles.content1 : styles.content}>
        <NavHashLink smooth className={styles.logoarea} to="/#top">
          <img className={styles.logo}
            alt="Vkushty logo"
            src={logo} />
          <p className={styles.slogan}>Ела Вкъщи<br />Eла на Върха</p>
        </NavHashLink>


        <div className={styles.logoarea}>
          <a href="tel:00359877714449" className={styles.phone}>
            {bulgarian.number}</a>

          <NavHashLink smooth className={styles.menuButton} to="/#contacts">
            {bulgarian.button}
          </NavHashLink>

          {/* <button onClick={handleLangChange} className={styles.menuButton}>
            {languageEn ? 'Български' : 'English'}
          </button> */}
        </div>
      </div>
    </div>
  )
}