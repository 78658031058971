import React from 'react';
// import pdfUrl from '../assets/pdf/vkushty.pdf'
import styles from './Download.module.css';

export default function Download({ text }) {

  const handleDownload = () => {
    const link = document.createElement('a');
    // link.href = pdfUrl;
    // link.setAttribute('download', 'vkushty.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <button
      className={styles.button}
      onClick={handleDownload}>

      {/* <ion-icon name="arrow-down"></ion-icon> */}

      {text || 'Get PDF'}
    </button>
  );
}

