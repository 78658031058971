import React from "react"
import styles from "./Contact.module.css"
import axios from "axios"
import { baseUrl } from "../config.js"

export default function Contact() {
  const [form, setForm] = React.useState({
    name: "",
    phone: 0,
    email: '',
    message: '',
  })
  const [button, setButton] = React.useState('Изпрати')
  const [error, setError] = React.useState('')

  const [language, setLanguage] = React.useState()

  const bulgarian = {
    contact: "Контакти",
    phone: "Телефон:",
    email: "Email:",
    phoneValue: "08777 1444 9",
    nameLabel: "Вашето име*:",
    phoneLabel: "Вашият телефон*:",
    messageLabel: "Съобщение:",
    send: "Изпрати",
  }

  function validateInput() {
    setButton('Изпраща се')
    if (form.name.length < 2) {
      setError('Ако обичате попълнете името си.')
      return false
    }
    if (form.phone.length < 2) {
      setError('Ако обичате попълнете телефонен номер.')
      return false
    }
    return true;
  }

  async function handleSubmit(e) {
    e.preventDefault()
    const valid = validateInput();
    if (!valid) return

    try {
      const response = await axios.post(`${baseUrl}/homemessage`, form)

      if (response.status === 201) {
        setButton('Получено.')
        setForm({
          name: "",
          number: 0,
          message: '',
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleChange(e) {
    setForm((prev) => {
      return { ...prev, [e.target.name]: e.target.value }
    })
  }

  return (
    <div className={styles.conWrap}>
      <hr />
      <h2 className={styles.h2}>
        {bulgarian.contact}</h2>

      <div className={styles.contactWrap}>

        <a className={styles.phoneNum}
          href={"tel:00359877714449"}>
          {bulgarian.phone} {bulgarian.phoneValue}
        </a>

        <a href="mailto:home@vkushty.com?subject=Посещение%20на%20място&body=Здравейте,%20искам%20да%20посетя%20обекта%20на:%20" className={styles.phoneNum}>
          Email: home@vkushty.com</a>
      </div>

      <div className={styles.formWrap}>
        <h4 className={styles.h2}
          style={{ color: "white" }}>Формуляр за контакти</h4>

        <form className={styles.contactForm}>
          <label>
            {bulgarian.nameLabel}
            <input type="text" maxLength={200} name="name"
              value={form.name} onChange={(e) => handleChange(e)} />
          </label>
          <label>
            {bulgarian.phoneLabel}
            <input type="number" maxLength={200} name="phone"
              onChange={(e) => handleChange(e)}
            />
          </label>
          <label>
            {bulgarian.email}
            <input type="email" maxLength={200} name="email"
              onChange={(e) => handleChange(e)}
            />
          </label>


          <label>
            {bulgarian.messageLabel}
            <textarea maxLength={200} name="message"
              onChange={(e) => handleChange(e)} />
          </label>

          <button
            className={styles.subButton} type="submit"
            onClick={(e) => handleSubmit(e)}>
            {button}
          </button>

        </form>
        {error && <p>{error}</p>}
      </div>
    </div>
  )
}