import React from "react"
import styles from "./Features.module.css"


export default function Features() {

  const bulgarian = {
    phone: "Телeфон 08 777 1444 9",
    address: "Ул. Изглед, Бистрица, София, 1444, България",
  }

  const featuresArray = ["897 метра надморска височина", "180° Панорамна гледка над София", "Затворен модерен комплекс", 'Чист въздух (10-35 AQI)', 'Безшумна локация (5-15 dB)','Страхотнo прайвъси'
  ]
  const featuresArray2 = ["Застроена площ 640-780 м2", "4 Спални + 4.5 Бани", "Просторна архитектура", "Екстра високи 3.7м тавани", "Прозорци от под до таван",
    "Парцел 920 м2", "Гараж за 4 коли", "Частна външна BBQ зона", "Басейн за плуване",
    "Фотоволтаици", 'Подготовка за умен дом', 'Термопомпа',]

  return (
    <div className={styles.featuresWrap}>

      <div className={styles.indivWrap}>
        <h3 className={styles.h2}>
          Характеристики</h3>

        <div className={styles.featureMap}>
          {featuresArray?.map((feature, i) => {
            return (
              <div key={i} className={styles.featureULItem}>
                <p className={styles.featureP}>+ {feature}</p>
              </div>
            )
          })}
        </div>
      </div>

      <div className={styles.indivWrap}>
        <h3 className={styles.h2}>
          Детайли Къщи</h3>

        <div className={styles.featureMap}>
          {featuresArray2?.map((feature, i) => {
            return (
              <div key={i} className={styles.featureULItem}>
                <p className={styles.featureP}>+ {feature}</p>
              </div>
            )
          })}

        </div>
      </div>
    </div>
  )
}